const state = {
    user:null,
    tagList:null,//副导航
    menuActive:'home',//导航选中
}

const mutations = {
    set_out(state){
        state.user = null;
        state.tagList = null;
        state.menuActive = 'home';
    },
    set_user(state,data){
        state.user = data;
    },
    set_tag(state,data){
        state.tagList = data;
    },
    set_active(state,data){
        state.menuActive = data;
    }
}

const actions = {
    up_user({commit},data){
        commit('set_user',data)
    },
    logout({commit}){
        commit('set_out')
    },
    up_tag({commit},data){
        commit('set_tag',data)
    },
    up_active({commit},data){
        commit('set_active',data)
    }
}

const getters = {
    get_user(state){
       return state.user;
    },
    get_tag(state){
        return state.tagList ?? [];
    },
    get_active(state){
        return state.menuActive;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};