import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem';
import cuTable from './components/cu-table.vue'
import cuDialog from './components/cu-dialog.vue'
import Print from 'vue3-print-nb'
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component('cuTable',cuTable);
app.component('cuDialog',cuDialog);
app.use(Print)
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(store);
app.use(router);
app.mount('#app');
