import {
	createRouter,
	createWebHistory
} from 'vue-router'
import store from '../store/index'
import Layout from '../views/layout/main.vue'


const routes = [{
		path: '/login',
		name: 'login',
		hidden: true,
		component: () => import('@/views/login/index.vue')
	},
	{
		path: '/register',
		name: 'register',
		hidden: true,
		component: () => import('@/views/login/register')
	},
	{
		path: '/',
		name: 'main',
		redirect: "/home",
		hidden: true,
		component: Layout,
		children: [{
				path: '/home',
				name: 'home',
				meta: {
					title: '首页',
					mid: 'home'
				},
				component: () => import('@/views/home/index')
			},
			{
				path: '/user/profile',
				name: 'user-profile',
				meta: {
					title: '个人资料',
					mid: 'profile'
				},
				component: () => import('@/views/layout/profile')
			},



		]
	},

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})


router.beforeEach((to, from, next) => {
	//在页面加载时读取sessionStorage里的状态信息
	if (window.sessionStorage.getItem("store")) {
		store.replaceState(
			Object.assign({}, JSON.parse(sessionStorage.getItem("store")))
		);
		window.sessionStorage.removeItem("store");
	}
	//登录检测
	let users = store.getters["base/get_user"];
	//未登录且访问的页面不是登录页，跳转至登录页面
	if (users == null && to.path !== "/login" && to.path !== '/register') {
		next({
			path: "/login",
		});
		document.title = to.meta.title ? to.meta.title + "德莱米克" : "德莱米克";
	} else if (to.path === "/login" || to.path === '/register') {
		//状态退出
		store.dispatch("base/logout").then((r) => r);
		next();
		document.title = to.meta.title ? to.meta.title + "德莱米克" : "德莱米克";
	} else {
		//检测是否已加载过路由
		if (router.options.routes.length > 4) {
			next();
			document.title = to.meta.title ? to.meta.title + "德莱米克" : "德莱米克";
		} else {
			let storeRouters = users.action;
			let mapStoreRouters = [];
			storeRouters.forEach(item => {
				//检测是否是一级菜单
				if (item.menu.pid === '0') {
					//检查是否存在
					let isMap = mapStoreRouters.findIndex(row => row.meta.mid === item.menu.mid);
					if (isMap === -1) {
						mapStoreRouters.push({
							path: item.menu.path,
							name: item.menu.name,
							component: Layout,
							meta: {
								mid: item.menu.mid,
								pid: item.menu.pid,
								power: item.power,
								title: item.menu.title,
								icon: item.menu.icon,
								com: item.menu.component,
							},
							children: [],
						})
					}
				} else {
					let parentIndex = mapStoreRouters.findIndex(row => row.meta.mid === item.menu.pid);
					if (parentIndex === -1) {
						//父级菜单还未找到问题
						let parent = storeRouters.find(row => row.mid === item.menu.pid);
						if (parent != null) {
							mapStoreRouters.push({
								path: parent.menu.path,
								name: parent.menu.name,
								component: Layout,
								meta: {
									mid: parent.menu.mid,
									pid: parent.menu.pid,
									power: parent.power,
									title: parent.menu.title,
									icon: parent.menu.icon,
									com: item.menu.component,
								},
								children: [{
									path: item.menu.path,
									name: item.menu.name,
									component: () => import(
										`../views/${item.menu.component}.vue`),
									meta: {
										mid: item.menu.mid,
										pid: item.menu.pid,
										power: item.power,
										title: item.menu.title,
										icon: item.menu.icon,
										com: item.menu.component,
									},
								}],
							})
						}
					} else {
						//父级菜单已找到
						mapStoreRouters[parentIndex].children.push({
							path: item.menu.path,
							name: item.menu.name,
							component: () => import(`../views/${item.menu.component}.vue`),
							meta: {
								mid: item.menu.mid,
								pid: item.menu.pid,
								power: item.power,
								title: item.menu.title,
								icon: item.menu.icon,
								com: item.menu.component,
							},
						})
					}
				}
			});
			//只有一级的菜单，特殊处理
			mapStoreRouters.forEach((item, index) => {
				if (item.children.length === 0) {
					mapStoreRouters[index] = {
						path: item.path + 's',
						name: item.name + 's',
						component: Layout,
						hidden: item.meta.com === 'layout',
						meta: {
							mid: item.meta.mid,
							pid: item.meta.pid,
							power: item.meta.power,
							title: item.meta.title,
							icon: item.meta.icon
						},
						children: [{
							path: item.path,
							name: item.name,
							meta: {
								mid: item.meta.mid,
								pid: item.meta.pid,
								power: item.meta.power,
								title: item.meta.title,
								icon: item.meta.icon
							},
							component: item.component,
						}],
					}
				}
			})
			//加入404页面
			mapStoreRouters.push({
				path: "/:pathMatch(.*)*",
				name: "notfound",
				hidden: true,
				component: () => import('@/views/layout/notfound')
			});
			//注入动态路由
			router.options.routes = router.options.routes.concat(mapStoreRouters);
			mapStoreRouters.forEach((item) => {
				router.addRoute(item);
			});
			next({
				...to,
				replace: true,
			});
		}
	}
});


export default router
