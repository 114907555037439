<template>
  <router-view/>
</template>

<script>
	// import DevicePixelRatio from './utils/devicePixelRatio';
export default {
  name: "App",
  created() {
	  // new DevicePixelRatio().init();
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", async () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  
  
  
};
</script>
<style>
@import "@/assets/main.css";
@import "@/assets/iconfont.css";


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-card__body{
  padding: 0;
}

.el-dialog__body{
  padding: 10px 20px;
}

.product-dialog-box .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view{
  white-space: nowrap;
}

.main-tag .el-scrollbar__wrap {
  overflow: hidden;
  margin-right: 0 !important;
}

.main-tag .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
}

.main-aside .el-scrollbar__wrap {
  overflow-x: hidden;
}

.mian-dan{

}
</style>
