import router from "@/router";
import store from '@/store';
import top from '@/utils/top'

export default {
    select: data => {
        //拿到全部副导航
        let list = store.getters['base/get_tag'];
        //检查是否已打开
        let isList = list.findIndex(item => item.meta.mid === data.meta.mid);
        if(isList === -1 && data.meta.mid !== 'home'){
            list.push(data);
            store.dispatch('base/up_tag', list).then(r => r);
        }

        store.dispatch('base/up_active', data.meta.mid).then(r => r);

        if (data.children === undefined){
            router.push({path: data.path}).then(r => r);
        }else {
            router.push({path:data.children[0].path}).then(r => r);
        }
    },
    close: data => {
        //拿到全部副导航
        let list = store.getters['base/get_tag'];
        let isList = list.findIndex(item => item.meta.mid === data.meta.mid);
        if(isList  === -1){
            top.msg('非法操作','error');
        }else {
            let obj = {};
            //检测是否需要跳转到首页
            if(list.length === 1){
                obj = {
                    path:'/home',
                    meta:{
                        mid:'home'
                    }
                }
            }else {
                //检测是否最后个
                if(isList === list.length - 1){
                    obj = Object.assign({},list[isList - 1])
                }else {
                    obj = Object.assign({},list[isList + 1]);
                }
            }

            //关闭当前项
            list.splice(isList,1);
            //更新数据
            store.dispatch('base/up_tag',list).then(r => r);
            store.dispatch('base/up_active',obj.meta.mid).then(r => r);

            if (obj.children === undefined){
                router.push({path: obj.path}).then(r => r);
            }else {
                router.push({path:obj.children[0].path}).then(r => r);
            }
        }

    }
}
