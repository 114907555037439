<template>
	<div class="main-page">
		<div class="main-header">
			<div class="flex just-between header-out">
				<div class="company-title">
					<!-- 福创-T6物流系统 -->
					德莱米克
				</div>
				<div class="user-option flex align-center">
					<div class="margin-right-5 cursor" @click.stop="handleUser()">{{ user != null ? user.name : '' }}</div>
					<div @click.stop="handleUser()" class="user-avatar">
						<img :src="user != null && user.avatar != null && user.avatar !== '' ? user.avatar : '/avatar.gif'" alt="" />
						<ul class="user-list" v-if="user_active">
							<li @click.stop="handleProfile()">
								<i class="iconfont icon-wode margin-right-5"></i>
								个人资料
							</li>
							<li @click.stop="option.show = true">
								<i class="iconfont icon-icon-out-light margin-right-5"></i>
								退出系统
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="main-aside" :class="visible ? 'visible' : 'hide'">
			<ul class="aside-list" v-if="!visible">
				<template v-for="(item, index) in $router.options.routes">
					<li v-if="!item.hidden" :key="index" class="list-item" @click="handleOpen(item, index)">
						<i class="iconfont" :class="item.meta.icon"></i>
						<ul class="list-sub" :class="isActive === index ? 'visible' : ''">
							<li class="title flex align-center" :class="child.meta.mid === active ? 'active' : ''" v-for="(child, chx) in item.children" @click.stop="handleSelect(child)">
								<i class="iconfont " :class="child.meta.icon"></i>
								<span class="text margin-left-5">{{ child.meta.title }}</span>
							</li>
						</ul>
					</li>
				</template>
			</ul>
			<ul class="aside-list" v-if="visible">
				<template v-for="(item, index) in $router.options.routes">
					<li class="aside-item" v-if="!item.hidden" :key="index" :class="isActive === index ? 'active' : ''" @click.stop="handleOpen(item, index)">
						<div class="title flex align-center">
							<i class="iconfont margin-left-5" :class="item.meta.icon"></i>
							<span class="margin-left-5 text" v-show="visible">{{ item.meta.title }}</span>
							<i class="iconfont icon-unfold1 margin-left-5" v-if="item.children[0].meta.pid !== '0'" :class="isActive === index ? 'transform' : ''" v-show="visible"></i>
						</div>
						<ul class="item-sub" v-if="item.children[0].meta.pid !== '0'">
							<li class="sub-item" :class="child.meta.mid === active ? 'active' : ''" v-for="(child, chx) in item.children" :key="chx" @click.stop="handleSelect(child)">
								<div class="title flex align-center">
									<i class="iconfont margin-left-15" :class="child.meta.icon"></i>
									<span class="margin-left-5 text" v-show="visible">{{ child.meta.title }}</span>
								</div>
							</li>
						</ul>
					</li>
				</template>
			</ul>
			<el-icon @click="handleVisible()" class="cursor handle-icon" v-if="visible"><Fold /></el-icon>
			<el-icon @click="handleVisible()" class="cursor handle-icon" v-if="!visible"><Expand /></el-icon>
		</div>
		<div class="main-tag" :class="visible ? 'visible' : 'hide'">
			<el-scrollbar style="width: 100%;height:calc(100% + 8px);">
				<div style="display: inline-block">
					<div class="tag-item" :class="active === 'home' ? 'active' : ''" @click.stop="handleSelect({ path: '/home', meta: { mid: 'home' } })">
						<div class="flex align-center"><span>首页</span></div>
					</div>
					<div class="tag-item" @click.stop="handleSelect(item)" :class="item.meta.mid === active ? 'active' : ''" v-for="(item, index) in tagList" :key="index">
						<div class="flex align-center">
							<span>{{ item.meta.title }}</span>
							<el-icon class="margin-left-5 tag-close" v-if="item.meta.mid === active" @click.stop="handleClose(item)"><Close /></el-icon>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
		<div class="main-content" :class="visible ? 'visible' : 'hide'"><router-view /></div>

		<cu-dialog :option="option" @close="option.show = false" @tap="logout()">
			<div>
				<el-icon class="margin-right-5 text-blue"><InfoFilled /></el-icon>
				<span>真的要退出吗？</span>
			</div>
		</cu-dialog>
	</div>
</template>

<script>
import base from '@/utils/base';
export default {
	name: 'main-page',
	data() {
		return {
			visible: true,
			isActive: -1,
			user_active: false,
			option: {
				show: false,
				title: '系统提示',
				cancelText: '不了',
				width: '300px'
			}
		};
	},
	computed: {
		tagList() {
			return this.$store.getters['base/get_tag'];
		},
		active() {
			return this.$store.getters['base/get_active'];
		},
		user() {
			return this.$store.getters['base/get_user'];
		}
	},
	mounted() {
		console.log(this.user);
	},
	methods: {
		handleUser() {
			this.user_active = !this.user_active;
		},
		handleVisible() {
			this.isActive = -1;
			this.visible = !this.visible;
		},
		handleOpen(item, index) {
			//检测是否有子集，有且展开，没有直接选中
			if (item.children[0].meta.pid === '0') {
				//表示为一级菜单
				base.select(item);
			} else {
				//表示还有二级菜单
				this.isActive = this.isActive === index ? -1 : index;
			}
		},
		handleProfile() {
			this.user_active = false;
			this.handleSelect({ path: '/user/profile', meta: { mid: 'profile', title: '个人资料' } });
		},
		handleSelect(item) {
			if (!this.visible && this.isActive !== -1) {
				this.isActive = -1;
			}
			base.select(item);
		},
		handleClose(item) {
			base.close(item);
		},
		logout() {
			this.option.show = false;
			this.$router.push({ path: '/login' });
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/color-config.scss';

.main-header {
	height: $header-height;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $header-bg;
	z-index: 2000;
	border-bottom: 1px solid #f0f0f0;
}

.header-out {
	height: 100%;
}

.company-title {
	display: flex;
	align-items: center;
	margin-left: 20px;
	font-weight: bold;
}

.user-option {
	height: calc(#{$header-height} - 20px);
	margin-top: 10px;
	margin-right: 20px;

	.user-avatar {
		width: calc(#{$header-height} - 20px);
		height: 100%;
		text-align: center;
		font-size: 14px;
		line-height: calc(#{$header-height} - 20px);
		border-radius: calc((#{$header-height} - 20px) / 2);
		position: relative;
		cursor: pointer;
		img {
			width: 100%;
			height: 100%;
			border-radius: calc((#{$header-height} - 20px) / 2);
		}
		.user-list {
			position: absolute;
			width: 100px;
			right: 0;
			top: 40px;
			background-color: #ffffff;
			padding: 5px;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
			li {
				list-style-type: none;
				height: 30px;
				line-height: 30px;
				font-size: 13px;
				border-bottom: 1px solid #f0f0f0;
				color: #333333;
				cursor: pointer;
			}
			li:hover {
				background-color: #f0f0f0;
			}
		}
	}
}

.main-aside {
	height: calc(100% - #{$header-height});
	position: fixed;
	top: $header-height;
	left: 0;
	background-color: $aside-bg;
	color: $aside-color;
	transition: width 0.2s;
	z-index: 2000;
}

.main-aside.visible {
	width: $aside-width-max;
	transition: width 0.2s;
}

.main-aside.hide {
	width: $aside-width-min;
	transition: width 0.2s;
}

.main-tag {
	position: fixed;
	top: $header-height;
	background-color: $header-bg;
	transition: all 0.2s;
	height: $tag-height;
	border-bottom: 1px solid #d8dce5;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
	z-index: 1000;

	.tag-item {
		height: calc(#{$tag-height} - 10px);
		margin: 6px 0 0 5px;
		padding: 0 8px;
		font-size: $tag-size;
		border: 1px solid #d8dce5;
		display: inline-block;
		cursor: pointer;
	}

	.tag-item.active {
		background-color: $aside-bg; //需要配置到主题上
		color: $aside-color;
	}

	.tag-close {
		border-radius: 50px;
	}

	.tag-close:hover {
		background-color: #999999;
	}
}

.main-tag.visible {
	width: calc(100% - #{$aside-width-max});
	left: $aside-width-max;
}

.main-tag.hide {
	width: calc(100% - #{$aside-width-min});
	left: $aside-width-min;
}

.main-content {
	margin-top: calc(#{$header-height} + #{$tag-height});
	transition: all 0.2s;
	padding: $content-padding;
	background-color: #f1f1f1;
	z-index: 500;
	min-height: calc(100vh - #{$header-height} - #{$tag-height} - #{$content-padding} - #{$content-padding});
}

.main-content.visible {
	width: calc(100% - #{$aside-width-max} - #{$content-padding} - #{$content-padding});
	margin-left: $aside-width-max;
}

.main-content.hide {
	width: calc(100% - #{$aside-width-min} - #{$content-padding} - #{$content-padding});
	margin-left: $aside-width-min;
}

.handle-icon {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.aside-list {
	width: 100%;
	height: 100%;

	.list-item {
		height: 40px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		font-size: $aside-size;
		cursor: pointer;
	}

	.list-item:hover {
		background-color: $aside-hover-bg;
	}

	.list-sub:before {
		content: ' ';
		position: absolute;
		left: -8px;
		top: 12px;
		width: 0;
		height: 0;
		border-right: 8px solid $aside-unfold-bg;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}

	.list-sub.visible {
		display: block;
	}

	.list-sub {
		display: none;
		position: absolute;
		top: 0;
		right: -152px;
		width: 140px;
		height: auto;
		color: #ffffff;
		background-color: $aside-unfold-bg;
		border-radius: 5px;

		.title {
			height: 40px;
			width: calc(100% - 20px);
			padding: 0 10px;
			line-height: 40px;
			border-radius: 5px;
			font-size: $aside-size;
			cursor: pointer;

			.text {
				width: calc(100% - 14px - 10px);
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.title.active {
			color: $aside-active-bg;
		}

		.title:hover {
			background-color: $aside-hover-bg;
		}
	}

	.aside-item {
		list-style: none;
		position: relative;
		height: 40px;
		overflow: hidden;
		transition: all 0.2s;

		.title {
			height: 40px;
			width: calc(100% - 25px);
			padding: 0 10px 0 15px;
			line-height: 40px;
			font-size: $aside-size;
			cursor: pointer;

			.text {
				width: calc(100% - 14px - 14px - 20px);
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.title:hover {
			background-color: $aside-hover-bg;
		}

		.iconfont {
			transition: all 0.2s;
		}

		.iconfont.transform {
			transform: rotate(180deg);
		}
	}

	.aside-item.active {
		height: auto;
		transition: all 0.2s;

		.item-sub {
			background-color: $aside-unfold-bg;

			.sub-item.active {
				background-color: $aside-active-bg;
			}
		}
	}
}

.el-pagination.is-background .btn-next.is-active,
.el-pagination.is-background .btn-prev.is-active,
.el-pagination.is-background .el-pager li.is-active {
	background-color: $aside-bg !important;
}
</style>
