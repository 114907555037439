<template>
  <div>
    <el-dialog :model-value="option.show ?? false"
               :width="option.width ?? '50%'"
               :title="option.title ?? '弹窗的标题'"
			   top="100px"
               :append-to-body="true"
               :close-on-click-modal="false"
               :show-close="option.close ?? true"
               @close="handleClick('cancel')">
      <slot></slot>
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #footer v-if="option.footer ?? true">
      <span class="dialog-footer">
        <el-button size="small" type="info"
                   @click="handleClick('cancel')">{{option.cancelText ?? '取消'}}</el-button>
        <el-popconfirm
            v-if="option.pop"
            width="220"
            confirm-button-text="确定"
            cancel-button-text="再想想"
            icon="InfoFilled"
            icon-color="#626AEF"
            :title="option.popText ?? ''"
            @confirm="handleClick('confirm')"
        >
          <template #reference>
            <el-button size="small" type="primary" :loading="option.loading ?? false">{{option.confirmText ?? '确认'}}</el-button>
          </template>
        </el-popconfirm>
        <el-button v-if="!option.pop" size="small" type="primary"
                   :loading="option.loading ?? false"
                   @click="handleClick('confirm')">{{option.confirmText ?? '确认'}}</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cu-dialog",
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          show: false,
          title: '弹窗标题',
          footer: true,
          width: '50%',
          loading: false,
          confirmText: '确定',
          cancelText: '取消'
        };
      }
    },
  },
  methods: {
    handleClick(val) {
      if (val === 'confirm') {
        this.$emit('tap', val)
      } else {
        if(!this.option.loading){
          this.$emit('close')
        }
      }
    },

  }
}
</script>

<style scoped>

</style>