<template>
	<div>
		<!--
  option.height 表格的高度，配置高度后，表格支持滚动
  option.id 每一行的key
  option.children 两级展示，必须配合option.id使用
  option.loading 表格的加载动画
  option.stripe  是否斑马纹
  option.sort 排序
  option.select 是否选择
  option.index 是否序号,
-->
		<el-table
			ref="cuTables"
			v-if="option.height"
			:height="option.height"
			:row-key="option.id ?? ''"
			:tree-props="option.children ?? {}"
			v-loading="option.loading ?? false"
			:data="option.data ?? []"
			:stripe="option.stripe ?? false"
			class="margin-top-10"
			style="width: 100%"
			border
			highlight-current-row
			header-cell-class-name="cu-table padding-lr-10"
			cell-class-name="cu-table-item padding-lr-10"
			:default-sort="option.sort ?? { order: 'descending' }"
		>
			<el-table-column v-if="option.select" type="selection" align="center" />
			<el-table-column type="index" label="#" v-if="option.index"></el-table-column>
			<slot></slot>
			<el-table-column width="340" fixed="right" prop="title" label="操作" v-if="option.oprator">
				<template #default="scope">
					<el-button link size="small" @click="handleView(scope.row)">查看</el-button>
					<el-button size="small" icon="edit" type="primary" @click="handleEdit(scope.row)" plain>编辑</el-button>
					<el-popconfirm
						confirm-button-text="确定"
						cancel-button-text="取消"
						icon="el-icon-info"
						icon-color="red"
						title="确定删除吗？"
						@confirm="handleRemove(scope.row)"
					>
						<template #reference>
							<el-button size="small" icon="delete" type="danger" plain>删除</el-button>
						</template>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-table
			ref="cuTables"
			highlight-current-row
			v-else
			:row-key="option.id ?? ''"
			:tree-props="option.children ?? {}"
			v-loading="option.loading ?? false"
			:data="option.data ?? []"
			:stripe="option.stripe ?? false"
			class="margin-top-10"
			style="width: 100%"
			border
			header-cell-class-name="cu-table"
			cell-class-name="cu-table-item"
			:default-sort="option.sort ?? { order: 'descending' }"
		>
			<el-table-column v-if="option.select" type="selection" align="center" />
			<el-table-column type="index" label="#" v-if="option.index"></el-table-column>
			<slot></slot>
			<el-table-column width="340" prop="title" fixed="right" label="操作" v-if="option.oprator" align="center">
				<template #default="scope">
					<el-button size="small" icon="view" plain @click="handleView(scope.row)">查看</el-button>
					<el-button size="small" icon="edit" type="primary" @click="handleEdit(scope.row)" plain>编辑</el-button>
					<el-popconfirm
						confirm-button-text="确定"
						cancel-button-text="取消"
						icon="el-icon-info"
						icon-color="red"
						title="确定删除吗？"
						@confirm="handleRemove(scope.row)"
					>
						<template #reference>
							<el-button size="small" icon="delete" type="danger" plain>删除</el-button>
						</template>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="margin-top-10" v-if="option.pagination ?? true">
			<el-pagination
				small
				background
				:page-sizes="[10, 50, 100, 500, 1000]"
				v-model:page-size="option.page_size"
				:total="option.total ?? 0"
				v-model:currentPage="option.page"
				@size-change="sizeChange"
				@current-change="pageChange"
				prev-text="上一页"
				next-text="下一页"
				:layout="option.layout ?? 'total,sizes,prev,pager,next'"
			></el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		option: {
			type: Object,
			default: () => {
				return {
					data: [], //数据
					page: 1, //页码
					total: 0, //总条数
					page_size: 10, //每页显示条数
					sort: {
						prop: '',
						order: 'descending'
					}, //排序规则
					currentRow: null
				};
			}
		}
	},
	methods: {
		sizeChange(val) {
			this.$emit('sizeChange', val);
		},
		pageChange(index) {
			this.$emit('pageChange', index);
		},
		handleView(row) {
			this.$emit('viewClick', row);
		},
		handleEdit(row) {
			this.$emit('editClick', row);
		},
		handleRemove(row) {
			this.$emit('removeClick', row);
		},
		getSelection() {
			return this.$refs['cuTables'].getSelectionRows();
		},
		
	}
};
</script>
<style>
.cu-table,
.cu-table-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
	font-size: 13px !important;
	font-weight: 400;
	color: #333333;
}
</style>
